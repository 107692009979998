<template>
  <v-layout class="home pa-2 ma-2" column>
    <v-toolbar flat color="">
        <v-toolbar-title>Order List</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn @click="opanManualBooking" color="green" dark>Manual Booking</v-btn>
    </v-toolbar>
    <!-- <div class="text-h4"></div> -->
    <v-card v-for="(obj, idx) in orders" :key="idx" class="ma-1 my-2 pa-0" elevation="1">
      <v-card-text class="pa-2">
        <v-layout>
          <v-flex xs2>
            <v-img height="150" :src="$store.state.config.mediaHost + obj.ac_id + '/thumbnail/' + obj.thumbnail"
            class="rounded-lg"></v-img>
          </v-flex>
          <v-flex xs4 class="pl-2">
            Nomor Order: <b>{{obj.no_order}}</b><br>
            Tanggal Submit: <b>{{obj.date_order}}</b><br>
            Tanggal Aktifitas: <b>{{obj.date | moment}}</b><br>
            Kegiatan: <b>{{obj.ac_name}}</b><br>
            Selection: <b>{{obj.selection.name}}</b><br>
            Qty Order: <b>{{obj.qty_order}} {{obj.selection.pricing_base}} </b><br>
            <span v-if="obj.category=='camping'">Durasi: <b>{{obj.duration}} malam</b></span>
          </v-flex>
          <v-flex xs4 class="pl-2">
            Total (Rp): <b>{{obj.total_price | currency}}</b><br>
            Total Cost (Rp): <b>{{obj.total_cost | currency}}</b><br>
            Nama Pemesan: <b>{{obj.user.name}}</b><br>
            Telp Pemesan: <b>{{obj.user.phone}}</b><br>
            <span v-if="obj.vendor_docs.length">Nama Vendor: <b>{{obj.vendor_docs[0].name}}</b></span><br>
            <span v-if="obj.vendor_docs.length">Telp Vendor: <b>{{obj.vendor_docs[0].phone}}</b></span><br>
            Status Order: <b>{{obj.status}} ({{obj.statusz[0].text}})</b><br>
          </v-flex>
          <v-flex xs2>
            <v-btn color="accent" small outlined class="ma-1" @click="viewDetails(obj)">
              View Details
            </v-btn>
            <v-btn color="accent" small outlined class="ma-1" @click="changeStatus(obj)">
              Ubah Status
            </v-btn>
            <v-btn color="accent" small outlined class="ma-1" @click="reschedule(obj)">
              Reschedule
            </v-btn>
            <v-btn v-if="obj.status=='P'" color="accent" small outlined class="ma-1" @click="inputCost(obj)">
              Adjustment
            </v-btn>
            <v-btn v-if="obj.status=='O'" color="accent" small outlined class="ma-1" @click="openConfirm(obj)">
              Confirm Order
            </v-btn>
            <v-btn v-if="obj.status=='O'" color="accent" small outlined class="ma-1" @click="openReject(obj)">
              Reject Order
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="orderDetailsDlg"
      v-if="orderDetailsDlg"
      scrollable
      max-width="80%"
    >
      <v-card>
        <v-card-title>Order Details</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 100%;">
          <div class="pl-2">
            <div class="body-1 font-weight-bold pt-2 pb-0">Order :</div>
            Nomor Order: <b>{{selectedDetails.no_order}}</b><br>
            Tanggal Submit: <b>{{selectedDetails.date_order}}</b><br>
            Tanggal Aktifitas: <b>{{selectedDetails.date | moment}}</b><br>
            Kegiatan: <b>{{selectedDetails.ac_name}}</b><br>
            Selection: <b>{{selectedDetails.selection.name}}</b><br>
            Description: <span class="font-italic">{{selectedDetails.selection.desc}}</span><br>

            Permintaan Khusus: <b>{{selectedDetails.notes}}</b><br>
            Catatan Admin: <b>{{selectedDetails.admin_notes}}</b><br>
            Status Order: <b>{{selectedDetails.status}}</b><br>
            Qty Order: <b>{{selectedDetails.qty_order}} {{selectedDetails.selection.pricing_base}} </b><br>
            <span v-if="selectedDetails.category=='camping'">Durasi: <b>{{selectedDetails.duration}} malam</b></span><br>
            Unit Price: <b>{{selectedDetails.unit_price | currency}}</b><br>
            Sub Total: <b>{{selectedDetails.sub_total | currency}}</b><br>

            <div v-if="selectedDetails.options.length" class="pt-2 pb-2">
              <div class="body-1 font-weight-bold">Options :</div>
              <div v-for="(opt, idx2) in selectedDetails.options" :key="idx2">
                <span>Name: <b>{{opt.name}}</b></span><br>
                <span>Desc: <b>{{opt.desc}}</b></span><br>
                <span>Price: <b>{{opt.price | currency}}</b></span> - 
                <span>Quantity: <b>{{opt.qty_option}}</b></span><br>
              </div>
              <div class="body-2 font-weight-bold">Total Options : {{selectedDetails.total_options | currency}}</div>
            </div>
            <div v-if="selectedDetails.total_voucher_promo">
                <span>Voucher Promo: </span>
                <span class="green--text font-weight-bold">-{{selectedDetails.total_voucher_promo | currency}}</span>
            </div>

            Order Fee: <b>{{selectedDetails.order_fee | currency}}</b><br>
            Grand Total: <b>{{selectedDetails.total_price | currency}}</b><br>

            <div class="body-1 font-weight-bold pt-2 pb-0">Customer :</div>
            Nama Pemesan: <b>{{selectedDetails.user.name}}</b><br>
            Telp Pemesan: <b>{{selectedDetails.user.phone}}</b><br>
            Email Pemesan: <b>{{selectedDetails.user.email}}</b><br>
            <div v-if="selectedDetails.vendor_docs.length" class="pt-2 pb-0">
              <div class="body-1 font-weight-bold">Vendor :</div>
              <span>Nama Vendor: <b>{{selectedDetails.vendor_docs[0].name}}</b></span><br>
              <span>Telp Vendor: <b>{{selectedDetails.vendor_docs[0].phone}}</b></span><br>
              <span>Alamat Vendor: <b>{{selectedDetails.vendor_docs[0].address}}</b></span><br>
            </div>
            <div v-if="selectedDetails.vendor_docs.length" class="pt-2 pb-0">
              <div class="body-1 font-weight-bold">Cost Details:</div>
              <div>
              Qty Order: <b>{{selectedDetails.qty_order}} {{selectedDetails.selection.pricing_base}} </b><br>
              Unit Cost: <b>{{selectedDetails.unit_cost | currency}}</b><br>
              Sub Total: <b>{{Number(selectedDetails.unit_cost) * Number(selectedDetails.qty_order) | currency}}</b><br>
              </div>
              <div class="body-2 font-weight-bold">Options :</div>
              <div v-for="(opt, idx2) in selectedDetails.options" :key="idx2">
                <span>{{idx2 + 1}}. </span>
                <span><b>{{opt.name}}</b></span> -
                <span>Unit Cost: <b>{{opt.cost | currency}}</b></span> - 
                <span>Quantity: <b>{{opt.qty_option}}</b></span><br>
              </div>
              <div class="body-2 font-weight-bold">Total Options : {{selectedDetails.total_options_cost | currency}}</div><br>
              <div class="body-1 font-weight-bold">Total Cost : {{selectedDetails.total_cost | currency}}</div>
              <br><br><br><br><br><br>
            </div>
          </div>          
          <pre>
            {{selectedDetails}}
          </pre>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="blue darken-1"
            text
            @click="orderDetailsDlg = false"
          >
            Close
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="orderDetailsDlg = false"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>    
    <v-overlay :value="onProgress">
        <v-progress-circular :size="50" color="amber" indeterminate></v-progress-circular>
    </v-overlay>  

    <v-dialog v-if="confirmDlg" v-model="confirmDlg" persistent max-width="400">
      <v-card>
        <v-card-title class="body-1">
          Konfirmasi Order {{selectedDetails.no_order}}<br>
          Atas Nama  {{selectedDetails.user.name}}
        </v-card-title>
        <v-card-text class="pb-5">
            <div class="pb-2">
            Anda akan mengkonfirmasi order dan akan mengirimkan notifikasi kepada customer. Lanjutkan?
            </div>
            <div class="pb-0">
              <span class="caption">Total Harga Vendor</span>
              <v-text-field v-model="vendor_price" outlined dense prefix="Rp" hide-details="auto"></v-text-field>
            </div>
            <div class="pb-2">
              <span class="caption">Catatan Khusus Untuk Customer</span>
              <v-textarea v-model="notes" outlined dense rows="2"></v-textarea>
            </div>
            <v-btn class="mb-2" color="primary" width="100%" depressed @click="confirmOrder('C')" :disabled="!vendor_price">
                Ya, Lanjutkan
            </v-btn>
            <v-btn color="" width="100%" depressed @click="confirmDlg = false">
                Tidak
            </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>    

    <v-dialog v-if="rejectDlg" v-model="rejectDlg" persistent max-width="400">
      <v-card>
        <v-card-title class="body-1">
          Konfirmasi Penolakan Order {{selectedDetails.no_order}}<br>
          Atas Nama  {{selectedDetails.user.name}}
        </v-card-title>
        <v-card-text class="pb-5">
            <div class="pb-2">
            Anda akan mengkonfirmasi penolakan order dan akan mengirimkan notifikasi kepada customer. Lanjutkan?
            </div>
            <div class="pb-2">
              <span class="caption">Alasan Penolakan</span>
              <v-textarea v-model="notes" outlined dense rows="2"></v-textarea>
            </div>
            <v-btn class="mb-2" color="primary" width="100%" depressed @click="confirmOrder('R')" :disabled="notes.length<5">
                Ya, Lanjutkan
            </v-btn>
            <v-btn color="" width="100%" depressed @click="rejectDlg = false">
                Tidak
            </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>      

    <v-dialog v-if="costDlg" v-model="costDlg" scrollable persistent max-width="90%">
      <v-card>
        <v-card-title class="body-1">
          Adjustment {{selectedDetails.no_order}}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pb-5" style="height:400px">
          <v-form ref="optionForm" id="optionForm" v-model="valid" lazy-validation>
          <v-simple-table
            fixed-header
            height="370px"
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    Item
                  </th>
                  <th class="text-left">
                    Qty
                  </th>
                  <th class="text-left">
                    Harga Satuan
                  </th>
                  <th class="text-left">
                    Jumlah Harga
                  </th>
                  <th class="text-left">
                    Unit Cost {{ac_details.cost_code_1}}
                  </th>
                  <th class="text-left" v-if="ac_details.cost_code_2">
                    Unit Cost {{ac_details.cost_code_2}}
                  </th>
                  <th class="text-left" v-if="ac_details.cost_code_3">
                    Unit Cost {{ac_details.cost_code_3}}
                  </th>
                  <th class="text-left">
                    Sub Total Cost
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style="text-transform:capitalize">
                    {{ selectedDetails.selection.pricing_base }}
                  </td>
                  <td>{{ selectedDetails.qty_order }}</td>
                  <td>
                    <v-text-field type="number" dense outlined hide-details="auto" v-model="selectedDetails.unit_price" class="compact-form" style="width:120px" @wheel="$event.target.blur()" :rules="reqRule"></v-text-field>
                  </td>
                  <td>
                    {{ Number(selectedDetails.unit_price) * Number(selectedDetails.qty_order) | numbers }}
                  </td>
                  <td>
                    <v-text-field type="number" dense outlined hide-details="auto" v-model="selectedDetails.selection.costs.cost_1" class="compact-form" style="width:120px" @wheel="$event.target.blur()" :rules="reqRule"></v-text-field>
                  </td>
                  <td v-if="ac_details.cost_code_2">
                    <v-text-field type="number" dense outlined hide-details="auto" v-model="selectedDetails.selection.costs.cost_2" class="compact-form" style="width:120px" @wheel="$event.target.blur()"></v-text-field>
                  </td>
                  <td v-if="ac_details.cost_code_3">
                    <v-text-field type="number" dense outlined hide-details="auto" v-model="selectedDetails.selection.costs.cost_3" class="compact-form" style="width:120px" @wheel="$event.target.blur()"></v-text-field>
                  </td>
                  <td>
                    {{ (Number(selectedDetails.selection.costs.cost_1||0) * Number(selectedDetails.qty_order)) + (Number(selectedDetails.selection.costs.cost_2||0) * Number(selectedDetails.qty_order)) + (Number(selectedDetails.selection.costs.cost_3||0) * Number(selectedDetails.qty_order))  | numbers }}
                  </td>
                  <td></td>
                </tr>
                <tr v-for="(item, idx) in selectedDetails.options" :key="idx">
                  <td v-if="!item.added">{{item.name}}</td>
                  <td v-else>
                    <!-- <v-text-field dense outlined hide-details="auto" v-model="item.name" class="compact-form" style="width:150px" :rules="reqRule"></v-text-field> -->
                    <v-autocomplete :items="options" item-value="name" v-model="item.name" item-text="name" dense outlined hide-details="auto" class="compact-form" @change="selectOption($event, item)" return-object></v-autocomplete>
                  </td>
                  <td v-if="!item.added">{{item.qty_option}}</td>
                  <td v-else><v-text-field type="number" dense outlined hide-details="auto" v-model="item.qty_option" class="compact-form" style="width:70px" @wheel="$event.target.blur()" :rules="reqRule"></v-text-field></td>
                  <td><v-text-field type="number" dense outlined hide-details="auto" v-model="item.price" class="compact-form" style="width:120px" @wheel="$event.target.blur()" :rules="reqRule"></v-text-field></td>
                  <td>{{ Number(item.qty_option) * Number(item.price) | numbers }}</td>
                  <td><v-text-field type="number" dense outlined hide-details="auto" v-model="item.cost" class="compact-form" style="width:120px" @wheel="$event.target.blur()"></v-text-field></td>
                  <td v-if="ac_details.cost_code_2"><v-text-field type="number" dense outlined hide-details="auto" v-model="item.cost_2" class="compact-form" style="width:120px" @wheel="$event.target.blur()"></v-text-field></td>
                  <td v-if="ac_details.cost_code_3"><v-text-field type="number" dense outlined hide-details="auto" v-model="item.cost_3" class="compact-form" style="width:120px" @wheel="$event.target.blur()"></v-text-field></td>
                  <td>{{ Number(item.qty_option) * Number(item.cost||0) + Number(item.qty_option) * Number(item.cost_2||0) + Number(item.qty_option) * Number(item.cost_3||0) | numbers }}</td>
                  <td>
                    <v-icon v-if="item.added" color="red" @click="removeOption(idx)">mdi-minus-circle-outline</v-icon>
                  </td>
                </tr>
                <tr class="font-weight-bold">
                  <td>Total</td>
                  <td></td>
                  <td></td>
                  <td>{{ totalSales | numbers }}</td>
                  <td></td>
                  <td v-if="ac_details.cost_code_2"></td>
                  <td v-if="ac_details.cost_code_3"></td>
                  <td>{{ totalCost | numbers }}</td>
                  <td></td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>            
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn depressed small class="white--text" color="blue" @click="addOption">Add</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed color="grey" @click="costDlg=false" class="white--text">Close</v-btn>
          <v-btn depressed color="green" @click="saveAdjustment" class="white--text">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>      
    <manual-booking ref="refManualBooking"></manual-booking>


    <v-dialog v-if="changeStatusDlg" v-model="changeStatusDlg" persistent max-width="400">
      <v-card>
        <v-card-title class="body-1">
          Ubah Status Order {{selectedDetails.no_order}}<br>
          Atas Nama  {{selectedDetails.user.name}}
        </v-card-title>
        <v-card-text class="pb-5">
            <div class="pb-2">
            Anda akan mengubah status order, proses ini akan mengirim notifikasi kepada customer, lanjutkan?
            </div>
            <div class="pb-0">
              <span class="caption">Status Baru</span>
              <v-select v-model="newStatus" :items="orderStatus" item-text="desc" item-value="code" outlined dense hide-details="auto"></v-select>
            </div>
            <div class="pb-2">
              <span class="caption">Catatan Khusus Untuk Customer</span>
              <v-textarea v-model="notes" outlined dense rows="2"></v-textarea>
            </div>
            <v-btn class="mb-2" color="primary" width="100%" depressed @click="confirmOrder(newStatus)" :disabled="!newStatus">
                Ya, Lanjutkan
            </v-btn>
            <v-btn color="" width="100%" depressed @click="changeStatusDlg = false">
                Tidak
            </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>    

    <v-dialog v-if="rescheduleDlg" v-model="rescheduleDlg" persistent max-width="400">
      <v-card>
        <v-card-title class="body-1">
          Reschedule Order {{selectedDetails.no_order}}<br>
          Atas Nama  {{selectedDetails.user.name}}
        </v-card-title>
        <v-card-text class="pb-5">
            <div class="pb-2">
            Anda akan mengubah tanggal aktifitas, proses ini akan mengirim notifikasi kepada customer, lanjutkan?
            </div>
            <div class="pb-0">
              <span class="caption">Tanggal Baru</span>
              <v-menu v-model="menuActivityDate" :close-on-content-click="false" :nudge-right="40"
                transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field outlined dense v-model="newDate" prepend-inner-icon="mdi-calendar"
                    readonly v-bind="attrs" v-on="on" hide-details="auto"></v-text-field>
                </template>
                <v-date-picker v-model="newDate" @input="menuActivityDate = false"></v-date-picker>
              </v-menu>
            </div>
            <div class="pb-2">
              <span class="caption">Catatan Khusus Untuk Customer</span>
              <v-textarea v-model="notes" outlined dense rows="2"></v-textarea>
            </div>
            <v-btn class="mb-2" color="primary" width="100%" depressed @click="confirmReschedule()" :disabled="!newDate">
                Ya, Lanjutkan
            </v-btn>
            <v-btn color="" width="100%" depressed @click="rescheduleDlg = false">
                Tidak
            </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>    

  </v-layout>
</template>

<script>
import moment from 'moment'
import ManualBooking from '../components/ManualBooking.vue'

export default {
  name: 'Home',
  data: () => ({
    onProgress: false,
    orderDetailsDlg: false,
    selectedDetails: {},
    confirmDlg: false,
    rejectDlg: false,
    changeStatusDlg: false,
    rescheduleDlg: false,
    vendor_price: null,
    notes: '',
    costDlg: false,
    valid: true,
    reqRule: [v => !!v || 'Harus diisi!'],
    ac_details: null,
    options: [],
    newStatus: null,
    newDate: null,
    menuActivityDate: null,
    orderStatus: [
      {code:'X', desc:'Canceled'},
      {code:'P', desc:'Paid'},
      {code:'E', desc:'Expired'},
      {code:'M', desc:'Manual Booking'}
    ]

  }),
  components: {
    ManualBooking
  },
  created() {
    this.onProgress = true
    // console.log('profile ',this.profile)
    const vendorCode = this.profile.vendor_code || null
    this.$store.dispatch('order/getObjs', vendorCode).then(response => {
      this.onProgress = false
      console.log(response)
    }, err => {
      this.onProgress = false
      console.log(err)
    })
  },
  computed: {
    orders() {
      return this.$store.state.order.objs
    },
    totalCost() {
      let totalCost = 0
      totalCost += Number(this.selectedDetails.selection.costs.cost_1||0) * Number(this.selectedDetails.qty_order)
      totalCost += Number(this.selectedDetails.selection.costs.cost_2||0) * Number(this.selectedDetails.qty_order)
      totalCost += Number(this.selectedDetails.selection.costs.cost_3||0) * Number(this.selectedDetails.qty_order)
      this.selectedDetails.options.forEach(item => {
        totalCost += ( Number(item.qty_option) * Number(item.cost||0) ) + ( Number(item.qty_option) * Number(item.cost_2||0) ) + ( Number(item.qty_option) * Number(item.cost_3||0) )
      })
      return totalCost
    },
    totalSales() {
      let totalSales = Number(this.selectedDetails.unit_price) * Number(this.selectedDetails.qty_order)
      this.selectedDetails.options.forEach(item => {
        totalSales += ( Number(item.qty_option) * Number(item.price) )
      })
      return totalSales
    },
    profile() {
      return this.$store.state.profile
    }
  },
  filters: {
    moment: function (date) {
        return moment(date).format('dddd, D MMMM YYYY');
    },
    numbers: function (number) {
        return Number(number).toLocaleString()
    }

  },

  methods: {
    selectOption(ev, item) {
      item.name = ev.name
      item.qty_option = '1'
      item.cost =  ev.cost || 0
      item.cost_2 =  ev.cost_2 || 0
      item.cost_3 =  ev.cost_3 || 0
      item.price = ev.price
    },
    async saveAdjustment() {
      await this.$refs.optionForm.validate();
      if (!this.valid) return;
      this.onProgress = true
      const docs = await this.$store.dispatch('order/updateOrderCost', this.selectedDetails)
      this.onProgress = false
      if (docs.result.ok) {
        this.costDlg = false
      }
    },
    removeOption(idx) {
      this.selectedDetails.options.splice(idx, 1)
    },
    addOption() {
      // if (this.selectedDetails.additional_cost == undefined) {
      //   this.$set(this.selectedDetails, 'additional_cost', [])
      // }
      this.selectedDetails.options.push({
        name: '',
        qty_option: '',
        cost: '',
        cost_2: '',
        cost_3: '',
        price: '',
        added: true
      })
      console.log(this.selectedDetails)
    },
    async inputCost(obj) {
      this.costDlg = true
      this.selectedDetails = obj
      this.ac_details = await this.$store.dispatch('activity/getObj', obj.ac_id)
      const selection = this.ac_details.selections.find(sel => sel._id == obj.selection._id)
      this.options = selection.options
      this.options.push(...this.ac_details.general_options)
      console.log(selection)
    },
    getTotalOptionCost(options){
      let total = 0
      options.forEach(element => {
        total += (element.cost * element.qty_option)
      });
      return total
    },
    viewDetails(obj) {
      this.orderDetailsDlg = true
      this.selectedDetails = obj
      console.log(obj)
    },
    reschedule(obj) {
      this.rescheduleDlg = true
      this.selectedDetails = obj
      console.log(obj)
    },
    changeStatus(obj) {
      this.changeStatusDlg = true
      this.selectedDetails = obj
      console.log(obj)
    },
    resetConfirmForm() {
      this.vendor_price = null,
      this.notes = ''
    },
    openConfirm(obj) {
      this.confirmDlg = true
      this.selectedDetails = obj
      this.vendor_price = obj.total_cost
      console.log(this.selectedDetails)
    },
    openReject(obj) {
      this.rejectDlg = true
      this.selectedDetails = obj
    },
    confirmOrder(newStatus) {
      let payload = { 
          "new_status" : newStatus, 
          "no_order" : this.selectedDetails.no_order,
          "vendor_total_price" : this.vendor_price,
          "admin_notes" : this.notes
      }
      this.$store.dispatch('order/postObj', payload).then(response => {
        this.confirmDlg = false
        this.rejectDlg = false
        this.changeStatusDlg = false
        console.log(response)
        this.onProgress = true
        this.$store.dispatch('order/getObjs').then(() => {
          this.onProgress = false
          this.resetConfirmForm()
        }, () => {
          this.onProgress = false
          this.resetConfirmForm()
        })        
      })
    },
    confirmReschedule() {
      let payload = { 
          "date" : this.newDate, 
          "no_order" : this.selectedDetails.no_order,
          "admin_notes" : this.notes
      }
      this.$store.dispatch('order/reschedule', payload).then(response => {
        this.rescheduleDlg = false
        console.log(response)
        this.onProgress = true
        this.$store.dispatch('order/getObjs').then(() => {
          this.onProgress = false
          this.resetConfirmForm()
        }, () => {
          this.onProgress = false
          this.resetConfirmForm()
        })        
      })
    },
    opanManualBooking() {
      this.$refs.refManualBooking.openDialog()
    }
  },

}
</script>
