<template>
    <v-container v-if="dialog">
        <v-dialog v-model="dialog" scrollable max-width="600px">
        <v-card>
            <v-form>
            <v-card-title>Manual Booking</v-card-title>
            <v-divider></v-divider>
            <v-card-text style="height: 550px;">
            <v-flex py-2>
              <v-layout wrap>
                <v-flex xs12 md12 px-2>
                    <span class="">Activity</span>
                    <v-autocomplete outlined dense :items="activities" item-text="name" item-value="_id" 
                    v-model="selectedActivity" return-object hide-details="auto" @change="onActivitySelected" 
                    @input="$v.selectedActivity.$touch()" @blur="$v.selectedActivity.$touch()" :error-messages="reqErrors($v.selectedActivity)"></v-autocomplete>
                </v-flex>
                <v-flex xs12 md12 px-2 v-if="selectedActivity">
                    <span class="">Selection</span>
                    <v-autocomplete outlined dense :items="selectedActivity.selections" item-text="name" item-value="_id"
                    v-model="order.selection" return-object hide-details="auto"
                    @input="$v.order.selection.$touch()" @blur="$v.order.selection.$touch()" :error-messages="reqErrors($v.order.selection)"></v-autocomplete>
                </v-flex>
                <v-flex xs12 md12 px-2>
                    <span class="">Tanggal Aktifitas</span>
                    <v-menu v-model="menuActivityDate" :close-on-content-click="false" :nudge-right="40"
                    transition="scale-transition" offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field outlined dense v-model="order.activity_date" prepend-inner-icon="mdi-calendar" :rules="reqRule"
                        readonly v-bind="attrs" v-on="on" hide-details="auto"></v-text-field>
                    </template>
                    <v-date-picker v-model="order.activity_date" @input="menuActivityDate = false" @change="setAvailability"></v-date-picker>
                    </v-menu>
                </v-flex>
                <v-flex xs12 md6 px-2 v-if="selectedActivity">
                    <template v-if="selectedActivity.category.code=='camping'">
                        <span class="">Durasi Kegiatan</span>
                        <v-text-field type="number" outlined dense v-model="order.duration" :rules="reqRule" required hide-details="auto"
                            suffix="malam"></v-text-field>
                    </template>
                </v-flex>
                <v-flex xs12 md6 px-2 v-if="selectedActivity">
                    <template v-if="order.selection">
                        <span class="">Qty Order</span>
                        <v-text-field type="number" outlined dense v-model="order.qty_order" :rules="reqRule" required hide-details="auto"
                            :suffix="order.selection.pricing_base" :max="this.avail" @change="qtyChanged" ref="qtyref"></v-text-field>
                        <span class="caption" v-if="this.avail">Tersedia: {{this.avail}}</span>
                    </template>
                </v-flex>
                <v-flex xs12 md12 px-2>
                    <span class="">Nama Customer</span>
                    <v-text-field outlined dense v-model="order.customer_name" :rules="reqRule" required hide-details="auto"></v-text-field>
                </v-flex>
                <v-flex xs12 md12 px-2>
                    <span class="">Telpon Customer</span>
                    <v-text-field type="number" outlined dense v-model="order.customer_phone" :rules="reqRule" required hide-details="auto"></v-text-field>
                </v-flex>
                <v-flex xs12 md12 px-2>
                    <span class="">Email Customer</span>
                    <v-text-field outlined dense v-model="order.customer_email" :rules="reqRule" required hide-details="auto"></v-text-field>
                </v-flex>
              </v-layout>
            </v-flex>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="text-right">
            <v-spacer></v-spacer>
            <v-btn @click="dialog=false">Cancel</v-btn>
            <v-btn color="primary" @click="save()">Save</v-btn>
            </v-card-actions>
            </v-form>
        </v-card>
        </v-dialog>
        <v-overlay :value="loading" style="z-index:999999">
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>
        </v-overlay>            
    </v-container>    
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
export default {
    mixins: [validationMixin],
    components: {
    },  
    validations: {
      selectedActivity: { required },
      order: {
        selection: { required }
      }
    },    
    data: () => ({
        dialog: false,
        loading: false,
        order: {
            act_id: '',
            duration: 1
        },
        avail: 0,
        reqRule: [v => !!v || 'Harus diisi!'],
        menuActivityDate: false,
        selectedActivity: null,
    }),
    computed: {
      activities () {
        return this.$store.state.activity.objs
      },
      profile () {
        return this.$store.state.profile
      }
    },
    methods: {
      reqErrors(obj) {
        const errors = []
        if (!obj.$dirty) return errors
        !obj.required && errors.push('Harus diisi.')
        return errors
      },
        openDialog() {
            this.dialog = true
            this.loading = true
            this.initialize();
            this.loading = false
        },

        initialize() {
            if (this.profile.type=='admin')
                this.$store.dispatch('activity/getObjs')
            else
                this.$store.dispatch('activity/getVendorActivities', encodeURI(this.profile.vendor_code))
        },

        onActivitySelected(obj) {
            console.log(this.selectedActivity)
            this.order.act_id = obj._id
            console.log(this.order)
        },

        async save(){
            // await this.$refs.rateForm.validate();
            // if (!this.valid) return;
            console.log(this.$v) 
            this.$v.$touch()           
            if (this.$v.$invalid) {
                console.log('invalid')
            }
            else {
                console.log('valid')
            }
            var order = {
                ac_id: this.selectedActivity._id,
                ac_name: this.selectedActivity.name,
                vendor: this.selectedActivity.vendor,
                category: this.selectedActivity.category.code,
                selection: this.order.selection,
                date: this.order.activity_date,
                qty_order: this.order.qty_order,
                duration: this.order.duration,
                unit_price: 0,
                unit_cost: 0,
                sub_total: 0,
                sub_total_cost: 0,
                total_options: 0,
                total_options_cost: 0,
                order_fee: 0,
                total_price: 0,
                total_global_discount: 0,
                total_cost: 0,
                options: {},
                tnc: '',
                cancelation_policy: '',
                user: {
                    name: this.order.customer_name,
                    email: this.order.customer_email,
                    phone: this.order.customer_phone
                }
            }         
            console.log(order)   
            this.$store.dispatch('order/manualBooking', order)
        }, 
        setAvailability() {
            this.loading = true
            const payload = {
                ac_id: this.selectedActivity._id,
                selected_date: this.order.activity_date,
                duration: this.order.duration
            }

            this.$store.dispatch('activity/getBookedCount', payload).then(response => {
                this.selectedActivity.selections.forEach(element => {
                    if (element.avail_check) {
                        this.$set(element, 'availability', element.avail_qty)
                        response.forEach(availdoc => {
                            const found = availdoc.find(doc => {return doc._id==element._id})
                            const availCount = (found) ? found.total : 0
                            if ((Number(element.avail_qty) - Number(availCount)) < Number(element.availability)){
                                element.availability = element.avail_qty - availCount
                                if (element.availability<0) element.availability = 0
                            }
                        });
                        console.log(element._id, this.order.selection)
                        if (element._id==this.order.selection) {
                            this.avail = element.availability
                        }
                    }
                });
                this.loading = false
            })
            console.log(this.selectedActivity)

        },
        qtyChanged() {
            console.log(this.$refs.qtyref)
            this.$refs.qtyref.valid = false
            console.log(this.$refs.qtyref.valid)
        }     
    }
}
</script>